/* eslint-disable import/prefer-default-export */
import { Link as RouterLink } from 'react-router-dom';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isLtiMode } from '../../../utils/platform';

const { isPlacementCentre } = require('@oup/shared-node-browser/org.js');

const getOrgLinks = key => {
  const pathnames = HubLayoutConstants.PATH_NAMES;
  const links = {
    lmsConfigDeployments: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.LMS_CONFIG}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.LMS_CONFIG}`
    },
    progress: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.PROGRESS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.PROGRESS}`
    },
    students: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.STUDENTS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.STUDENTS}`
    },
    staff: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.STAFF}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.STAFF}`
    },
    orgClasses: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.CLASSES}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.CLASSES}`
    },
    placementTests: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.PLACEMENT_TESTS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.PLACEMENT_TESTS}`
    },
    licences: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.LICENCES}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.LICENCES}`
    },
    credits: {
      link: `${pathnames.ORGANIZATION_PATH}${pathnames.CREDITS}`,
      matcher: `${pathnames.ORGANIZATION_PATH}${pathnames.CREDITS}`
    }
  };
  return links[key];
};

const getOrgLinksData = ({
  content,
  pathname,
  isLmsLtiTool = false,
  currentOrganisationLti = false,
  orgConfig,
  placementTests
}) => {
  const linksData = [
    {
      to: getOrgLinks('students').link,
      component: RouterLink,
      primaryText: content.org_menu_students,
      active: pathname.includes(getOrgLinks('students').matcher)
    },
    {
      to: getOrgLinks('staff').link,
      component: RouterLink,
      primaryText: content.org_menu_staff,
      active: pathname.includes(getOrgLinks('staff').matcher)
    },
    {
      to: getOrgLinks('orgClasses').link,
      component: RouterLink,
      primaryText: content.org_menu_classes,
      active: pathname === getOrgLinks('orgClasses').matcher
    }
  ];

  if (!currentOrganisationLti) {
    linksData.push({
      to: getOrgLinks('licences').link,
      component: RouterLink,
      primaryText: content.org_menu_licences,
      active: pathname === getOrgLinks('licences').matcher
    });
  }

  if (featureIsEnabled('hub-self-service') && isLmsLtiTool && !isLtiMode()) {
    linksData.unshift({
      to: getOrgLinks('lmsConfigDeployments').link,
      component: RouterLink,
      primaryText: content.org_lms_config_deployments,
      active: pathname === getOrgLinks('lmsConfigDeployments').matcher
    });

    linksData.push({
      to: getOrgLinks('credits').link,
      component: RouterLink,
      primaryText: content.org_menu_credits,
      active: pathname === getOrgLinks('credits').matcher
    });
  }
  if (
    featureIsEnabled('opt-main-features') &&
    isPlacementCentre(orgConfig) &&
    !currentOrganisationLti &&
    !isLtiMode()
  ) {
    linksData.unshift({
      to: getOrgLinks('placementTests').link,
      component: RouterLink,
      primaryText: content.org_menu_placementTests,
      active: pathname === getOrgLinks('placementTests').matcher
    });
  }

  let updatedLinksData = linksData;
  if (featureIsEnabled('opt-main-features') && isPlacementCentre(orgConfig)) {
    updatedLinksData = linksData.filter(link => link.to !== getOrgLinks('orgClasses').link);
  }

  if (pathname.includes(HubLayoutConstants.PATH_NAMES.TASKS_PATH)) {
    const lastUrlSegm = window.location.pathname.split('/').pop();
    updatedLinksData = [
      {
        primaryText: content.my_tasks_to_do_tab,
        to: '/myTasks',
        component: RouterLink,
        active: lastUrlSegm === 'myTasks',
        ariaAttributes: {
          current: lastUrlSegm === 'myTasks'
        },
        ...(placementTests?.length && lastUrlSegm === 'myTasks' ? { textBadge: placementTests?.length } : {})
      },
      {
        primaryText: content.my_tasks_upcoming_tab,
        to: '/myTasks/upcoming',
        component: RouterLink,
        active: lastUrlSegm === 'upcoming',
        ariaAttributes: {
          current: lastUrlSegm === 'upcoming'
        }
      },
      {
        primaryText: content.my_tasks_completed_tab,
        to: '/myTasks/completed',
        component: RouterLink,
        active: lastUrlSegm === 'completed',
        ariaAttributes: {
          current: lastUrlSegm === 'completed'
        }
      },
      {
        primaryText: content.my_tasks_missing_tab,
        to: '/myTasks/missing',
        component: RouterLink,
        active: lastUrlSegm === 'missing',
        ariaAttributes: {
          current: lastUrlSegm === 'missing'
        }
      }
    ];
  }

  return updatedLinksData;
};

export { getOrgLinksData };
