import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { parse as parseQueryString } from 'query-string';

import Heading from '@oup/shared-front-end/src/components/Heading';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard/Wizard.js';

import styles from './RegistrationComplete.scss';

import withLocalizedContent from '../../../language/withLocalizedContent.js';
import Illustration from '../../Illustration/Illustration.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import { getIdpLoginUrl } from '../../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { formStates } from '../../../redux/reducers/registration/registration.reducer.js';

function RegistrationComplete({
  localizedContent: { registrationPage: content },
  isYoungLearner,
  platform,
  formState
}) {
  const { stepHandler, setStepOut } = useWizard();

  stepHandler(async () => {
    const url = await getIdpLoginUrl(null, { platform });
    window.location.href = url;
    setStepOut(true);
  });

  return (
    <div data-testid="REGISTER_WIZARD_COMPLETED_STEP">
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <Heading text={content.header_registration_complete} size="medium" className={styles.heading} />
          {formState === formStates.COMPLETE ? (
            (isYoungLearner && <p className={styles.subHeading}>{content.young_learner_registration_complete}</p>) || (
              <p className={styles.subHeading}>{content.registration_complete}</p>
            )
          ) : (
            <p className={styles.subHeading}>{content.registration_error}</p>
          )}
        </div>
        <div className={styles.illustration}>
          <Illustration
            illustrationSrc={
              formState === formStates.COMPLETE
                ? (isYoungLearner && HubIllustrationConstants.ORANGE_MONSTER) || HubIllustrationConstants.SUCCESS
                : HubIllustrationConstants.ERROR
            }
            illustrationAltText={
              formState === formStates.COMPLETE
                ? (isYoungLearner && HubIllustrationAltText.ORANGE_MONSTER) || HubIllustrationAltText.SUCCESS
                : HubIllustrationAltText.ERROR
            }
          />
        </div>
      </div>
    </div>
  );
}

RegistrationComplete.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isYoungLearner: PropTypes.bool,
  platform: PropTypes.string,
  formState: PropTypes.string
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(({ registration }) => {
    const queryString = parseQueryString(window.location.search) || {};

    return {
      formState: registration.formState,
      isYoungLearner: window.location.href.includes('young-learner'), // to be replaced with something more robust in future
      platform: queryString.providerId || queryString.platform
    };
  })
)(RegistrationComplete);
