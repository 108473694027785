import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import {
  AM_SYNC_RESULT_STATUS,
  PLACEMENT_TEST_STATUS,
  USER_TEST_SESSION_STATUSES
} from '@oup/shared-node-browser/constants';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import styles from './PlacementTest.scss';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import {
  openPlacementTestOnboardingWizard,
  openPlacementTestPanel,
  openPlacementTestSessionInformationModal
} from '../../../redux/actions/hubUi';
import { loadPlacementTestResultsRequest } from '../../../redux/actions/placementTestResults';
import { continueEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import copyToClipboard from '../Utils/copyToClipboard';
import { formatDate } from '../../../components/PlacementTestOnBoardingWizard/utils/helpers';
import withLocalizedContent from '../../../language/withLocalizedContent';
import downloadAsFile from '../../../utils/downloadAsFile';

function PlacementTestItem({
  test,
  id,
  openPlacementTestOnboardingWizardAction,
  continueEditedPlacementTestAction,
  customOrgId,
  openPlacementTestPanelAction,
  openPlacementTestSessionInformationModalAction,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  isMyTasksPage,
  isPlacementTestsPage,
  orgName,
  dropdownTop,
  displayStatus = true,
  downloadPlacementTestResultsAction,
  resultsFileUrl,
  downloadResultUrlReady
}) {
  const isBulkImportInProgress = test?.configurations?.CONFIGURATION_PLACEMENT_TEST?.studentsBulkImportIsInProgress;

  useEffect(() => {
    if (downloadResultUrlReady) {
      downloadAsFile({ url: resultsFileUrl });
    }
  }, [downloadResultUrlReady]);

  const handleContinueSetupSession = () => {
    if (test.configurations.CONFIGURATION_PLACEMENT_TEST.status === PLACEMENT_TEST_STATUS.DRAFT) {
      openPlacementTestOnboardingWizardAction();
      const placementTest = test.configurations.CONFIGURATION_PLACEMENT_TEST;
      const placementTestSessionNameValue = test.classGroup.className;
      continueEditedPlacementTestAction({
        placementTestSessionId: test._id,
        placementTestSessionNameValue,
        placementTest
      });
    }
  };

  const renderDraftListing = () =>
    isBulkImportInProgress ? (
      <LoadingSpinner text={hubContent.students_are_being_imported} customClass={styles.processingSpinner} />
    ) : (
      <>
        <span className={`${styles.thumbnail}`} data-testid="SPAN">
          <SVGIcon className={styles.warningIcon} glyph={GLYPHS.ICON_WARNING_CIRCLE} />
        </span>
        <div className={styles.draftIncomplete}>
          <div className={styles.placementTestStatusGroup}>
            <b>{hubContent.draftIncompleteLabel}</b>
          </div>
        </div>
      </>
    );

  const renderProcessingListing = (
    <div
      className={`${styles.placementTestItemDateCreatedColumn} ${styles.placementTestItemColumn}`}
      data-testid="PLACEMENT_TEST_LISTING"
    >
      <span className={styles.thumbnail} data-testid="SPAN">
        <SVGIcon className={styles.loadingIcon} glyph={GLYPHS.ICON_LOADING} />
      </span>
      <div className={styles.draftIncomplete}>
        <div className={styles.placementTestStatusGroup}>
          <b>{hubContent.sessionProcessing}</b>
        </div>
      </div>
    </div>
  );

  const renderFailedListing = (
    <div
      className={`${styles.placementTestItemDateCreatedColumn} ${styles.placementTestItemColumn}`}
      data-testid="PLACEMENT_TEST_LISTING"
    >
      <span className={styles.thumbnail} data-testid="SPAN">
        <SVGIcon className={styles.warningIcon} glyph={GLYPHS.ICON_ERROR_CIRCLE} />
      </span>
      <div className={styles.draftIncomplete}>
        <div className={styles.placementTestStatusGroup}>
          <b>{hubContent.sessionSyncFailed}</b>
        </div>
      </div>
    </div>
  );

  // TODO: replace hardcoded values for tests taken and average score with data from BE when API ready
  const renderResultsListing = status => (
    <div className={styles.listingContainer}>
      {isBulkImportInProgress ? (
        <LoadingSpinner text={hubContent.students_are_being_imported} customClass={styles.processingSpinner} />
      ) : (
        <>
          <div className={styles.listingColumn}>
            <p className={styles.label}>{placementTestsContent.placement_test_session_listing_handed_in}</p>
            <div className={styles.result}>
              {status === 'UPCOMING' ? (
                <span>-</span>
              ) : (
                <>
                  <span>0</span>
                  <span> / </span>
                  <span>0</span>
                </>
              )}
            </div>
          </div>
          <div className={styles.listingColumn}>
            <p className={styles.label}>{placementTestsContent.placement_test_session_listing_average_score}</p>
            <div className={styles.result}>{status === 'UPCOMING' ? <span>-</span> : <span>B1</span>}</div>
          </div>
        </>
      )}
    </div>
  );

  const renderListingColumn = status => {
    if (test.configurations.CONFIGURATION_PLACEMENT_TEST.amSyncResult?.status === AM_SYNC_RESULT_STATUS.PENDING) {
      return renderProcessingListing;
    }
    if (test.configurations.CONFIGURATION_PLACEMENT_TEST.amSyncResult?.status === AM_SYNC_RESULT_STATUS.FAILED) {
      return renderFailedListing;
    }

    return (
      <div
        className={`${styles.placementTestItemDateCreatedColumn} ${styles.placementTestItemColumn}`}
        data-testid="PLACEMENT_TEST_LISTING"
      >
        {status === 'DRAFT' && renderDraftListing()}
        {(status === 'COMPLETED' ||
          status === (USER_TEST_SESSION_STATUSES.TODO || PLACEMENT_TEST_STATUS.ACTIVE) ||
          status === 'UPCOMING') &&
          renderResultsListing(status)}
      </div>
    );
  };

  const getDropdownOptions = () => ({
    testStatus: test.configurations.CONFIGURATION_PLACEMENT_TEST.status || '',
    showResultsToStudents: test.configurations.CONFIGURATION_PLACEMENT_TEST.showResultsToStudents
  });

  const getDropdownActions = () => {
    const copyText = copyToClipboard();
    const classCode = `${customOrgId}-${test?.joiningCode?.code}`;
    const placementTest = test.configurations.CONFIGURATION_PLACEMENT_TEST;
    const placementTestSessionNameValue = test.classGroup.className;
    return {
      viewProgress: event => {
        event.preventDefault();
      },
      downloadProgress: event => {
        event.preventDefault();
        downloadPlacementTestResultsAction({ sessionId: test._id });
      },
      viewSessionInfo: event => {
        continueEditedPlacementTestAction({
          placementTestSessionId: test._id,
          placementTestSessionNameValue,
          joiningCode: test?.code,
          placementTest
        });
        openPlacementTestSessionInformationModalAction();
        event.preventDefault();
      },
      continueSetupSession: event => {
        handleContinueSetupSession();
        event.preventDefault();
      },
      manageSession: event => {
        continueEditedPlacementTestAction({
          placementTestSessionId: test._id,
          placementTestSessionNameValue,
          joiningCode: test?.code,
          placementTest,
          placementTestSessionDateCreated: test.dateCreated
        });
        openPlacementTestPanelAction();
        event.preventDefault();
      },
      downloadResults: event => {
        event.preventDefault();
        downloadPlacementTestResultsAction({ sessionId: test._id });
      },
      copyJoiningCode: () => {
        copyText(classCode);
      },
      accessTest: event => {
        event.preventDefault();
      },
      downloadReportCard: event => {
        event.preventDefault();
      }
    };
  };

  const renderStatus = () => {
    let status = test.configurations.CONFIGURATION_PLACEMENT_TEST.status;
    if (
      test.configurations.CONFIGURATION_PLACEMENT_TEST.amSyncResult?.status === AM_SYNC_RESULT_STATUS.PENDING ||
      (isBulkImportInProgress && test.configurations.CONFIGURATION_PLACEMENT_TEST.status === 'DRAFT')
    ) {
      // don't need this as a constant as will only be shown here
      status = PLACEMENT_TEST_STATUS.PROCESSING;
    }
    if (status === USER_TEST_SESSION_STATUSES.TODO) {
      status = PLACEMENT_TEST_STATUS.ACTIVE;
    }
    return <span className={`${styles.testStatus} ${styles[status]}`}>{status}</span>;
  };
  const renderThumbnail = () => {
    let iconGlyph = GLYPHS.ICON_DRAFT_PLACEMENT_TEST;
    switch (true) {
      case test.configurations.CONFIGURATION_PLACEMENT_TEST.placementTestType === 'OPT_YL':
        iconGlyph = GLYPHS.ICON_YOUNG_LEARNERS_TEST;
        break;
      case !test.configurations.CONFIGURATION_PLACEMENT_TEST.placementTestType:
        iconGlyph = GLYPHS.ICON_PENCIL_IN_CIRCLE;
        break;
      default:
        iconGlyph = GLYPHS.ICON_DRAFT_PLACEMENT_TEST;
    }

    return <SVGIcon glyph={iconGlyph} />;
  };

  return (
    <li
      className={`${styles.placementTestItemRow} ${isMyTasksPage ? styles.placementTestItemRowMyTasks : ''} ${
        isMyTasksPage && test.configurations.CONFIGURATION_PLACEMENT_TEST.status === PLACEMENT_TEST_STATUS.COMPLETED
          ? styles.placementTestItemRowCompletedTasks
          : ''
      }`}
    >
      <div className={styles.placementTestItemColumn} onClick={handleContinueSetupSession} role="none">
        <div className={styles.thumbnail}>{renderThumbnail()}</div>
      </div>

      <div className={styles.placementTestItemColumn} onClick={handleContinueSetupSession} role="none">
        {displayStatus && renderStatus()}
        <span className={styles.placementTestName}>
          {test.classGroup.className}
          {isMyTasksPage &&
            test.configurations.CONFIGURATION_PLACEMENT_TEST.status === USER_TEST_SESSION_STATUSES.TODO && (
              <TextLink to="#" target="_blank" />
            )}
        </span>
        <div className={styles.sessionDates}>
          {test.configurations.CONFIGURATION_PLACEMENT_TEST.testStartDate && (
            <div>
              <b>{placementTestsContent.placement_test_summary_start} </b>
              {formatDate(test.configurations.CONFIGURATION_PLACEMENT_TEST.testStartDate)}
            </div>
          )}
          {test.configurations.CONFIGURATION_PLACEMENT_TEST.testEndDate && (
            <div>
              <b>{placementTestsContent.placement_test_summary_end} </b>
              {formatDate(test.configurations.CONFIGURATION_PLACEMENT_TEST.testEndDate)}
            </div>
          )}
          {orgName && (
            <div className={styles.orgName}>
              <SVGIcon glyph={GLYPHS.ICON_ORG} viewBox="0 0 45.203 12.812" />
              {orgName}
            </div>
          )}
        </div>
      </div>

      {!isMyTasksPage && renderListingColumn(test.configurations.CONFIGURATION_PLACEMENT_TEST.status)}

      {isMyTasksPage && test.configurations.CONFIGURATION_PLACEMENT_TEST.status === 'COMPLETED' && (
        <div className={styles.scoreContainer}>
          <span>{placementTestsContent.placement_test_score}</span>
          <div className={styles.score}>
            {test.configurations.CONFIGURATION_PLACEMENT_TEST.showResultsToStudents
              ? 'B2'
              : placementTestsContent.placement_test_talk_to_your_teacher}
          </div>
        </div>
      )}

      <div className={styles.dropdownMenu}>
        <HubDropdownMenu
          dropdownData={getDropdownItems(
            'placementTestSession',
            hubContent,
            getDropdownOptions(),
            getDropdownActions(),
            null,
            null,
            null,
            isMyTasksPage,
            placementTestsContent
          )}
          parentId={id}
          customClassname={styles.placementTestDropdown}
          dropdownTop={dropdownTop}
          isPlacementTests={isPlacementTestsPage || isMyTasksPage}
        />
      </div>
    </li>
  );
}

PlacementTestItem.propTypes = {
  test: PropTypes.object.isRequired,
  id: PropTypes.string,
  openPlacementTestOnboardingWizardAction: PropTypes.func,
  continueEditedPlacementTestAction: PropTypes.func,
  customOrgId: PropTypes.string,
  openPlacementTestPanelAction: PropTypes.func,
  downloadPlacementTestResultsAction: PropTypes.func,
  openPlacementTestSessionInformationModalAction: PropTypes.func,
  localizedContent: PropTypes.object,
  dropdownTop: PropTypes.bool,
  isMyTasksPage: PropTypes.bool,
  isPlacementTestsPage: PropTypes.bool,
  orgName: PropTypes.string,
  displayStatus: PropTypes.bool,
  resultsFileUrl: PropTypes.string,
  downloadResultUrlReady: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ loadPlacementTestResultsReducer: { resultsFileUrl, success: downloadResultUrlReady } }) => ({
      resultsFileUrl,
      downloadResultUrlReady
    }),
    {
      openPlacementTestOnboardingWizardAction: openPlacementTestOnboardingWizard,
      continueEditedPlacementTestAction: continueEditedPlacementTest,
      openPlacementTestPanelAction: openPlacementTestPanel,
      downloadPlacementTestResultsAction: loadPlacementTestResultsRequest,
      openPlacementTestSessionInformationModalAction: openPlacementTestSessionInformationModal
    }
  )
)(PlacementTestItem);
