import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { Heading, TextLink, Checkbox, RadioButtonGroup } from '@oup/shared-front-end/src';
import withLocalizedContent from '../../../language/withLocalizedContent.js';
import {
  setTermsAndConditions,
  setUnderAgeAccepted,
  setMarketPref,
  submitForm,
  formStates
} from '../../../redux/reducers/registration/registration.reducer.js';
import styles from './CaptureTermsAndConditionsStep.scss';

function CaptureTermsAndConditionsStep({
  localizedContent: { registrationPage: content },
  setTermsAndConditionsAction,
  setUnderAgeAcceptedAction,
  setMarketPrefAction,
  registerUserAction,
  marketingEmailAcceptedValue,
  termsAndConditionsValue,
  formState
}) {
  const { setNextStepDisabled, stepHandler, nextStep } = useWizard();

  const [ageSelectedOption, setAgeSelectedOption] = useState('');

  useEffect(() => {
    setNextStepDisabled(!termsAndConditionsValue);
  }, [termsAndConditionsValue]);

  useEffect(() => {
    if (formState === formStates.COMPLETE) {
      nextStep();
    }
  }, [formState]);

  stepHandler(() => {
    if (formState === formStates.COMPLETE) {
      return;
    }
    registerUserAction();
    throw new Error();
  });

  const termsAndConditionsCheckbox = (
    <div className={styles.termsCheckboxContainer}>
      <Checkbox
        checked={termsAndConditionsValue}
        onChange={() => setTermsAndConditionsAction(!termsAndConditionsValue)}
        id="terms_and_conditions_consent"
        label={
          <>
            {content.i_agree_to_the}{' '}
            <TextLink to={content.terms_and_conditions_link} target="_blank">
              {content.terms_and_conditions}
            </TextLink>
          </>
        }
      />
    </div>
  );

  return (
    <div data-testid="REGISTER_WIZARD_TS_&_CS_STEP" className={styles.stepContainer}>
      <div>
        <Heading className={styles.heading} text={content.terms_and_conditions_title} size="medium" />
      </div>

      <div className={styles.contentContainer}>
        <div>
          <p className={styles.sectionTitle}>{content.please_select_an_option_below}</p>
          <RadioButtonGroup
            variant="outline"
            name="age_selection"
            legend={content.please_select_an_option_below}
            checkedValue={[ageSelectedOption]}
            radioGroup={[
              {
                id: 'under_16_age',
                label: (
                  <>
                    {content.consent_under_age} <strong>{content.under}</strong>
                  </>
                ),
                value: 'under'
              },
              {
                id: 'over_16_age',
                label: (
                  <>
                    {content.consent_over_age_start} <strong>{content.over}</strong> {content.consent_over_age_end}
                  </>
                ),
                value: 'over'
              }
            ]}
            onChange={({ target: { value = '' } }) => {
              setUnderAgeAcceptedAction(value === 'under');
              setMarketPrefAction(value === 'over');
              setAgeSelectedOption(value);
            }}
          />
        </div>
        {ageSelectedOption === 'under' ? (
          <div>
            <p className={styles.sectionTitle}>{content.consent_parent_agreement}</p>
            <div>
              <p>
                {content.consent_parent_terms_conditions}{' '}
                <TextLink to={content.terms_and_conditions_link} target="_blank">
                  {content.terms_and_conditions}
                </TextLink>
              </p>
              {termsAndConditionsCheckbox}
            </div>
          </div>
        ) : null}
        {ageSelectedOption === 'over' ? (
          <>
            {termsAndConditionsCheckbox}
            {termsAndConditionsValue && (
              <div>
                <p className={styles.sectionTitle}>{content.marketing_preferences_title}</p>
                <Checkbox
                  checked={!marketingEmailAcceptedValue}
                  onChange={() => setMarketPrefAction(!marketingEmailAcceptedValue)}
                  id="marketing_pref"
                  label={content.consent_emails_product_services}
                  dataAttributes={{ testId: 'MARKETING_PREF' }}
                />
              </div>
            )}
          </>
        ) : null}
        {ageSelectedOption ? (
          <div>
            <p className={styles.sectionTitle}>{content.privacy_policy_info_title}</p>
            <div>
              {content.our}{' '}
              <TextLink to={content.privacy_policy_link} target="_blank">
                {content.privacy_policy}
              </TextLink>{' '}
              {content.and}{' '}
              <TextLink to={content.children_privacy_policy_link} target="_blank">
                {content.children_privacy_policy}
              </TextLink>{' '}
              {content.privacy_policy_info_description}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

CaptureTermsAndConditionsStep.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setTermsAndConditionsAction: PropTypes.func.isRequired,
  setUnderAgeAcceptedAction: PropTypes.func.isRequired,
  setMarketPrefAction: PropTypes.func.isRequired,
  marketingEmailAcceptedValue: PropTypes.bool,
  termsAndConditionsValue: PropTypes.bool,
  registerUserAction: PropTypes.func.isRequired,
  formState: PropTypes.string
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    ({ registration }) => ({
      marketingEmailAcceptedValue: registration.marketingEmailAcceptedValue,
      termsAndConditionsValue: registration.termsAndConditionsValue,
      formState: registration.formState
    }),
    {
      setTermsAndConditionsAction: setTermsAndConditions,
      setUnderAgeAcceptedAction: setUnderAgeAccepted,
      setMarketPrefAction: setMarketPref,
      registerUserAction: submitForm
    }
  )
)(CaptureTermsAndConditionsStep);
