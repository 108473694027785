import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import animationStyles from '../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { clearWizardState } from '../../redux/actions/placementTestOnboardingWizard';

import { closePlacementTestOnboardingWizard } from '../../redux/actions/hubUi';
import { closeForm } from '../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../language/withLocalizedContent';
import { featureIsEnabled } from '../../globals/envSettings';

function PlacementTestOnboardingWizardFooter({
  placementTestSessionNamePending,
  placementTestSessionCreationLoading,
  closeWizardAction,
  clearWizardStateAction,
  clearPlacementTestSessionCreateStateAction,
  localizedContent: { hubGlossary: hubContent }
}) {
  const { step, nextStep, nextStepDisabled, isLastStep } = useWizard();

  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');
  const loading = placementTestSessionNamePending || placementTestSessionCreationLoading ? animationStyles.loading : '';

  const handleCancel = () => {
    closeWizardAction();
    clearWizardStateAction();
    clearPlacementTestSessionCreateStateAction();
  };

  const setPrimaryButtonLabel = () => {
    if (canShowStudentResults ? step === 6 : step === 5) {
      return hubContent.finish;
    }

    if (isLastStep) {
      return hubContent.done_button;
    }

    return hubContent.next;
  };

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      secondaryButtonAction={step < 2 && handleCancel}
      secondaryButtonLabel={step < 2 ? hubContent.cancel : ''}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        loading,
        dataAttributes: {
          testid: isLastStep
            ? 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_DONE_BUTTON'
            : 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_NEXT_BUTTON'
        }
      }}
      secondaryButtonAttributes={{
        dataAttributes: { testid: 'ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_CANCEL_BUTTON' }
      }}
    />
  );
}

PlacementTestOnboardingWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  placementTestSessionNamePending: PropTypes.bool,
  placementTestSessionCreationLoading: PropTypes.bool,
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearPlacementTestSessionCreateStateAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    ({ placementOnboardingWizard }) => ({
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending,
      placementTestSessionCreationLoading: placementOnboardingWizard.placementTestSessionCreationLoading
    }),
    {
      clearWizardStateAction: clearWizardState,
      closeWizardAction: closePlacementTestOnboardingWizard,
      clearPlacementTestSessionCreateStateAction: closeForm
    }
  )
)(PlacementTestOnboardingWizardFooter);
